import { FC, useCallback, useRef, useState } from 'react';
import { IPanelProps, IRenderFunction, Panel, PanelType, ProgressIndicator } from '@fluentui/react';
import { t } from "i18next";
import { ICommonProps } from '../../ICommonProps';
import { EnumCase, EnumSearchCriteria, IArchiveMeetingResult, IMessage } from '../../../model/model';
import { useMessages } from '../../../context/MessagesContext';
import { IMessageCaseViewRef, MessageCaseView } from '../../chatArchiving/panel/MessageCaseView';
import { IMessageNewDocumentViewRef, MessageNewDocumentView } from '../../chatArchiving/panel/MessageNewDocumentView';
import { StepWizard } from '../../chatArchiving/StepWizard';
import MeetingSelectionView from './MeetingSelectionView';
import { RenderPanelHeader } from '../../common/RenderPanelHeader';
import { RenderPanelFooter } from '../../common/RenderPanelFooter';
import { MessagesActionType } from '../../../context/MessagesReducer';

interface IMeetingArchivePanelProps extends ICommonProps, IPanelProps {
    isOpen: boolean;
    selectedMeetings?: IMessage[];
    showMeetingSelection?: boolean;
    onSaveComplete?(response: IArchiveMeetingResult): void;
    onSaveError?(error: Error): void;
}

const MeetingArchivePanel: FC<IMeetingArchivePanelProps> = (props) => {
    const { isOpen, selectedMeetings, showMeetingSelection, dataProvider, onDismiss, onSaveComplete } = props;
    const { messageState, dispatch } = useMessages();
    const messageCaseViewRef = useRef<IMessageCaseViewRef>(null);
    const messageNewDocumentViewRef = useRef<IMessageNewDocumentViewRef>(null);
    const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);

    const closePanel = useCallback(() => {
        dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: 0 } });
        dispatch({ type: MessagesActionType.UpdateSearchCriteria, payload: { searchCriteria: EnumSearchCriteria.Case } });
        dispatch({
            type: MessagesActionType.ArchiveMessages, payload: {
                caseDocumentData: {
                    ...messageState.caseDocumentData,
                    caseNumber: "",
                    documentNumber: "",
                    documentTitle: messageState.meetingState.meeting.topic,
                    selectionNewOrExisting: EnumCase.ExistingCase
                }
            }
        });
        onDismiss && onDismiss();
    }, [dispatch, messageState.caseDocumentData, messageState.meetingState.meeting.topic, onDismiss]);

    const onSavingComplete = useCallback((response: IArchiveMeetingResult) => {
        setIsSavingInProgress(false);
        if (onSaveComplete)
            onSaveComplete(response);
    }, [onSaveComplete]);

    const onRenderNavigationContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <RenderPanelHeader closePanel={closePanel} />
        ), [closePanel]);

    const onRenderFooterContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <RenderPanelFooter {...props} dataProvider={dataProvider} closePanel={closePanel}
                showMeetingSelection={showMeetingSelection}
                selectedMessages={selectedMeetings}
                messageCaseViewRef={messageCaseViewRef}
                messageNewDocumentViewRef={messageNewDocumentViewRef}
                onSaveComplete={onSavingComplete}
                archivingInProgress={setIsSavingInProgress} />
        ), [closePanel, dataProvider, onSavingComplete, selectedMeetings, showMeetingSelection]
    );

    return <Panel type={PanelType.custom} customWidth="418px" isOpen={isOpen} onDismiss={closePanel} closeButtonAriaLabel={t('Close')}
        styles={{
            content: { marginTop: '-10px' }, footer: { backgroundColor: 'white', borderTop: '1px solid #e9e9e9' }
        }} isFooterAtBottom={true} onRenderNavigation={onRenderNavigationContent}
        onRenderFooterContent={onRenderFooterContent} isHiddenOnDismiss={false}>
        {
            isSavingInProgress && <div style={{ marginTop: '10px' }}><ProgressIndicator /></div>
        }
        {
            showMeetingSelection ?
                <StepWizard switchToStep={messageState.stepWizard}>
                    <MeetingSelectionView {...props} />
                    <MessageCaseView {...props} ref={messageCaseViewRef} isFileArchiveAction={isOpen} />
                    <MessageNewDocumentView {...props} ref={messageNewDocumentViewRef} selectedMessages={selectedMeetings} />
                </StepWizard> :
                <StepWizard switchToStep={messageState.stepWizard}>
                    <MessageCaseView {...props} ref={messageCaseViewRef} isFileArchiveAction={isOpen} />
                    <MessageNewDocumentView {...props} ref={messageNewDocumentViewRef} selectedMessages={selectedMeetings} />
                </StepWizard>
        }
    </Panel>;
};

export default MeetingArchivePanel;