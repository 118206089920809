/* istanbul ignore file */

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { CombineUrl } from "../helpers/UrlHelper";
import { ITeamServiceRequest, ISharepointFileResponse, IAccessGroupResponse, IBackendCapabilities, ICaseDocumentResponse, ICaseResponse, IClassCode, IClassCodeResponse, IEntityHistoryItem, IGetDocumentRequest, IGetHistoryParameterRequest, IGetSearchParameterRequest, IPublicityClassResponse, ISearchCase, ArchiveFileOnExistingDocumentRequestParameter, AppPermissionsRequestParameter, SyncFileWithTeamsRequestParameter, EnumGraphApiAuthMode, IMessageResponse, ArchiveChatsOnExistingDocumentsRequestParameter, IDocumentResponse, FetchUserProfilePhotoRequestParameter, IConversationMember, ArchiveChatsRequestParameter, IMeetingResponse, SetChannelAutomaticArchivingRequestParameter, ChannelAutomaticArchivingInfo, GetChannelAutomaticArchivingInfoRequestParameter, ArchiveMeetingRequestParameter, IArchiveMeetingResult } from "../model/model";
import { IP360Provider } from "./IP360Provider";
import { ErrorType } from '../context/ApiErrorHandler';

const apiPath = "/Biz/v2/api/call/SI.Client.Api.Custom.Teams/SI.Client.Api.Custom.Teams/TeamsService";

export class P360Provider implements IP360Provider {
    public httpClient?: AxiosInstance;

    /** Retry count for authentication refresh used in interactive authentication */
    private retryCount = 0;
    /** Maximum retry count for authentication refresh used in interactive authentication */
    private readonly maxRetryCount = 3;

    initialize(baseUrl: string, getToken?: () => Promise<string>, interactiveAuthHandler?: (forceRefresh?: boolean) => Promise<string>, errorHandler?: (error: ErrorType) => void): void {
        this.httpClient = axios.create({
            baseURL: CombineUrl(baseUrl, apiPath),
            withCredentials: true,
        });

        this.configureTokenAuthentication(getToken);
        this.configureInteractiveAuthentication(interactiveAuthHandler, errorHandler);
        this.configureErrorHandler(errorHandler);

        if (process.env.REACT_APP_USE_AUTHKEY === 'true' && process.env.REACT_APP_TEST_API_AUTHKEY) {
            this.httpClient.interceptors.request.use((config) => {
                config.params = { ...config.params, authkey: process.env.REACT_APP_TEST_API_AUTHKEY };
                return config;
            });
        }
    }

    public checkConnection(): Promise<AxiosResponse<{ Status: "ok"; }>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<{ Status: 'ok' }>("Ping");
    }

    public getChannelFiles(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse[]>("GetChannelFiles", data);
    }

    public getFileItems(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse[]>("GetFileItems", data);
    }

    public getAccessGroups(): Promise<AxiosResponse<IAccessGroupResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post("GetAccessGroup");
    }

    public getCases(data: ISearchCase): Promise<AxiosResponse<ICaseResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ICaseResponse[]>("GetCases", data);
    }
    public addConnectionToNewCase(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("AddConnectionToNewCase", data);
    }

    public addConnectionToExistingCase(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("AddConnectionToExistingCase", data);
    }

    public archiveFilesAsSingleDocument(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("ArchiveFile", data);
    }
    public checkIfFileItemIsArchived(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("CheckIfFileItemIsArchived", data);
    }
    public getFilesArchivedStatus(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse[]>("GetFilesArchivedStatus", data);
    }
    public getProcess(data: ISearchCase): Promise<AxiosResponse<IClassCodeResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IClassCodeResponse>("GetProcess", data);
    }
    public getRecordTypes(data: ITeamServiceRequest): Promise<AxiosResponse<IClassCode[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IClassCode[]>("GetRecordTypes", data);
    }
    public getConfigurationType(): Promise<AxiosResponse<IBackendCapabilities>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IBackendCapabilities>("GetConfigurationType");
    }
    public getPublicityClasses(): Promise<AxiosResponse<IPublicityClassResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IPublicityClassResponse[]>("GetPublicityClass");
    }
    public getActionTypes(data: ITeamServiceRequest): Promise<AxiosResponse<IClassCode[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IClassCode[]>("GetActionTypes", data);
    }
    public getRecordTypesBasedOnActionType(data: ITeamServiceRequest): Promise<AxiosResponse<IClassCode[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IClassCode[]>("GetRecordTypesBasedOnActionType", data);
    }
    public getDocuments(data: IGetDocumentRequest): Promise<AxiosResponse<ICaseDocumentResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ICaseDocumentResponse>("GetDocuments", data);
    }
    public getDocumentsByDocumentNumber(data: IGetDocumentRequest): Promise<AxiosResponse<ICaseDocumentResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ICaseDocumentResponse>("GetDocumentsByDocumentNumber", data);
    }
    public importFiles(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse[]>("ImportFiles", data);
    }
    public getHistory(data: IGetHistoryParameterRequest): Promise<AxiosResponse<IEntityHistoryItem[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IEntityHistoryItem[]>("GetHistory", { parameter: data });
    }
    public searchDocuments(data: IGetSearchParameterRequest): Promise<AxiosResponse<ICaseDocumentResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ICaseDocumentResponse>("SearchDocuments", { parameter: data });
    }
    checkAppPermissions(data: AppPermissionsRequestParameter): Promise<AxiosResponse<boolean>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<boolean>("CheckAppPermissions", { parameter: data });
    }
    setAppPermissions(data: AppPermissionsRequestParameter): Promise<AxiosResponse<boolean>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<boolean>("SetAppPermissions", { parameter: data });
    }
    public archiveFileOnExistingDocument(data: ArchiveFileOnExistingDocumentRequestParameter): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("ArchiveFileOnExistingDocument", { parameter: data });
    }
    public syncFileWithTeams(data: SyncFileWithTeamsRequestParameter): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        data.enumGraphApiAuthMode = EnumGraphApiAuthMode.IdToken;
        return this.httpClient.post<ISharepointFileResponse>("SyncFileWithTeams", data);
    }
    public getChatMessages(data: ITeamServiceRequest): Promise<AxiosResponse<IMessageResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IMessageResponse>("GetChatMessages", data);
    }
    public archiveChatMessagesOnExistingDocument(data: ArchiveChatsOnExistingDocumentsRequestParameter): Promise<AxiosResponse<IDocumentResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IDocumentResponse>("ArchiveChatMessagesOnExistingDocument", { parameter: data });
    }
    public fetchUserProfilePhotos(data: FetchUserProfilePhotoRequestParameter): Promise<AxiosResponse<IConversationMember[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IConversationMember[]>("FetchUserProfilePhotos", { parameter: data });
    }
    public archiveChatMessages(data: ArchiveChatsRequestParameter): Promise<AxiosResponse<IDocumentResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IDocumentResponse>("ArchiveChats", { parameter: data });
    }
    public getMeetingDetails(data: ITeamServiceRequest): Promise<AxiosResponse<IMeetingResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IMeetingResponse>("GetMeetingDetails", data);
    }
    public archiveMeetings(data: ArchiveMeetingRequestParameter): Promise<AxiosResponse<IArchiveMeetingResult>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IArchiveMeetingResult>("ArchiveMeetings", { parameter: data });
    }
    public archiveMeetingOnExistingDocument(data: ArchiveMeetingRequestParameter): Promise<AxiosResponse<IArchiveMeetingResult>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IArchiveMeetingResult>("ArchiveMeetingOnExistingDocument", { parameter: data });
    }

    public setChannelAutomaticArchiving(data: SetChannelAutomaticArchivingRequestParameter): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("SetChannelAutomaticArchiving", { parameter: data });
    }

    public getChannelAutomaticArchivingInfo(data: GetChannelAutomaticArchivingInfoRequestParameter): Promise<AxiosResponse<ChannelAutomaticArchivingInfo>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ChannelAutomaticArchivingInfo>("GetChannelAutomaticArchivingInfo", data);
    }

    private configureTokenAuthentication(tokenHandler?: () => Promise<string>): void {
        if (!tokenHandler || !this.httpClient) { return; }

        this.httpClient.interceptors.request.use(async (config) => {
            const token = await tokenHandler();
            config.headers = { ...config.headers, Authorization: `Bearer ${token}` } as AxiosRequestHeaders;
            return config;
        });
    }

    private configureInteractiveAuthentication(interactiveAuthHandler?: (forceRefresh?: boolean) => Promise<string>, errorHandler?: (error: ErrorType) => void): void {
        if (!interactiveAuthHandler || !this.httpClient) { return; }

        let isRefreshing: Promise<void> | null = null;
        const requestQueue: ((error?: AxiosError) => void)[] = [];

        const onAuthenticationRefreshed = () => {
            requestQueue.forEach((callback) => callback());
            requestQueue.length = 0;
            this.retryCount = 0;
        };

        const addRequestToQueue = (callback: (error?: AxiosError) => void) => {
            requestQueue.push(callback);
        };

        const handleAuthentication = async (forceRefresh?: boolean) => {
            if (isRefreshing || this.retryCount >= this.maxRetryCount) { return; }

            this.retryCount++;
            isRefreshing = interactiveAuthHandler(forceRefresh)
                .then(() => {
                    onAuthenticationRefreshed();
                })
                .catch((error: Error) => {
                    errorHandler?.({ message: error.message } as Partial<ErrorType> as unknown as ErrorType);
                    return Promise.reject(error);
                })
                .finally(() => {
                    isRefreshing = null;
                });

            await isRefreshing;
        };

        this.httpClient.interceptors.request.use(async (config) => {
            handleAuthentication(false);
            return config;
        });

        // Add a response interceptor to refresh the authentication token if 401 is received
        this.httpClient.interceptors.response.use(
            (response) => response,
            async (error: AxiosError) => {
                if (error.response?.status !== 401) {
                    return Promise.reject(error);
                }

                try {
                    await handleAuthentication(true);
                    // While authentication is in progress, add requests to the queue
                    const originalRequest = error.config;
                    return new Promise((resolve, reject) => {
                        addRequestToQueue(() => {
                            axios.request(originalRequest as AxiosRequestConfig)
                                .then(resolve)
                                .catch(reject);
                        });
                    });
                } catch (authError) {
                    return Promise.reject(authError instanceof Error ? authError : new Error(String(authError)));
                }
            }
        );
    }

    private configureErrorHandler(errorHandler?: (error: ErrorType) => void): void {
        if (!errorHandler || !this.httpClient) { return; }

        this.httpClient.interceptors.response.use(
            (response) => response,
            (error: AxiosError) => {
                errorHandler(error);
                return Promise.reject(error);
            }
        );
    }
}