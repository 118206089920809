export class Constants {
    static readonly Capabilities = {
        Get360DocumentsWithFiles: "Get360DocumentsWithFiles",
        ImportFiles: "ImportFiles",
        DocumentsSearch: "DocumentsSearch",
        GetDocumentsByDocumentNumber: "GetDocumentsByDocumentNumber",
        GetHistory: "GetHistory",
        AppPermissionsEndpoints: "AppPermissionsEndpoints",
        ArchiveOnExistingDocument: "ArchiveOnExistingDocument",
        TruncateLongFileNames: "TruncateLongFileNames",
        DenyRestricted: "DenyRestricted",
        DisallowCaseCreation: "DisallowCaseCreation",
        ChatArchiving: "ChatArchiving",
        AutomaticArchiving: "AutomaticArchiving"
    };

    static readonly HomeDir = 'Home';
    static readonly CaseTitleMaxLength = 254;
    static readonly DocumentTitleMaxLength = 254;
    static readonly FileNameMaxLength = 254;
    static readonly FileNameMaxLengthWithoutBLTruncate = 200;
    static readonly MeetingRecordingNotAvailable = "NotAvailable";
    static readonly EventDetail = {
        CallRecordingEventMessageDetail: '#microsoft.graph.callRecordingEventMessageDetail',
        CallEndedEventMessageDetail: '#microsoft.graph.callEndedEventMessageDetail',
        CallRecordingStatusSuccess: 'success'
    };

    static readonly ConfigKeys = {
        P360Url: 'p360url',
        DefaultP360Url: 'default360Url',
        UseInteractiveAuthentication: 'iauth',
        ShowInteractiveAuthentication: 'showIAuth',
        DefaultUseInteractiveAuthentication: 'defaultIAuth',
        ForceInteractiveAuthentication: 'forceIAuth'
    };
}