import { FC, RefObject, useCallback } from "react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { t } from "i18next";
import { useBoolean } from "@fluentui/react-hooks";
import { useMessages } from "../../context/MessagesContext";
import { ArchiveMeetingRequestParameter, EnumCase, EnumSearchCriteria, IArchiveMeetingResult, IMessage, ISelectedMeetingData, ITeamRequest } from "../../model/model";
import { MessagesActionType } from "../../context/MessagesReducer";
import { IMessageCaseViewRef } from "../chatArchiving/panel/MessageCaseView";
import { IMessageNewDocumentViewRef } from "../chatArchiving/panel/MessageNewDocumentView";
import { useTeams } from "../../context/TeamsContext";
import { MapTeamsContextToITeamRequest } from "../../helpers/Mapper";
import { ICommonProps } from "../ICommonProps";

interface IRenderPanelFooterProps extends ICommonProps {
    closePanel: () => void;
    showMeetingSelection?: boolean;
    selectedMessages?: IMessage[];
    messageCaseViewRef: RefObject<IMessageCaseViewRef>;
    messageNewDocumentViewRef: RefObject<IMessageNewDocumentViewRef>;
    onSaveComplete?(response: IArchiveMeetingResult): void;
    archivingInProgress?(value: boolean): void;
}

export enum MeetingPanelStep {
    MeetingSelectionView = 0,
    CaseView = 1,
    DocumentView = 2
}

export enum ChatPanelStep {
    CaseView = 0,
    DocumentView = 1
}

export const RenderPanelFooter: FC<IRenderPanelFooterProps> = (props) => {
    const { closePanel, showMeetingSelection, selectedMessages, messageCaseViewRef, messageNewDocumentViewRef, dataProvider, onSaveComplete, archivingInProgress } = props;
    const { messageState, dispatch } = useMessages();
    const { teamsState } = useTeams();
    const [isSavingInProgress, { setTrue: savingInProgress, setFalse: savingFinished }] = useBoolean(false);

    const isNewCaseSelected = useCallback(() => {
        return messageState.caseDocumentData.selectionNewOrExisting === EnumCase.NewCase;
    }, [messageState.caseDocumentData.selectionNewOrExisting]);

    const isDocumentSearchCriteriaSelected = useCallback(() => {
        return messageState.searchCriteria === EnumSearchCriteria.Document;
    }, [messageState.searchCriteria]);

    const isArchivingInProgress = useCallback((value: boolean) => {
        if (archivingInProgress)
            archivingInProgress(value);
    }, [archivingInProgress]);

    const onClosePanel = useCallback(() => {
        savingFinished();
        isArchivingInProgress(false);
        closePanel();
    }, [closePanel, isArchivingInProgress, savingFinished]);

    const defaultButtonTextInMeetingContext = useCallback(() => {
        if (messageState.stepWizard === MeetingPanelStep.MeetingSelectionView) {
            return t("Cancel");
        }
        else if (messageState.stepWizard === MeetingPanelStep.CaseView || messageState.stepWizard === MeetingPanelStep.DocumentView) {
            return t("Go back");
        }
    }, [messageState.stepWizard]);

    const defaultButtonTextInChatContext = useCallback(() => {
        if (messageState.stepWizard === ChatPanelStep.CaseView) {
            return t("Cancel");
        }
        else if (messageState.stepWizard === ChatPanelStep.DocumentView) {
            return t("Go back");
        }
    }, [messageState.stepWizard]);

    const primaryButtonTextInMeetingContext = useCallback(() => {
        if (messageState.stepWizard === MeetingPanelStep.MeetingSelectionView) {
            return t("Continue");
        }
        if (messageState.stepWizard === MeetingPanelStep.CaseView) {
            if (isNewCaseSelected()) {
                return t("Continue");
            }
            return isDocumentSearchCriteriaSelected() ? t("Save to 360") : t("Continue");
        }
        if (messageState.stepWizard === MeetingPanelStep.DocumentView) {
            return t("Save to 360");
        }
    }, [isDocumentSearchCriteriaSelected, isNewCaseSelected, messageState.stepWizard]);

    const primaryButtonTextInChatContext = useCallback(() => {
        if (messageState.stepWizard === ChatPanelStep.CaseView) {
            if (isNewCaseSelected()) {
                return t("Continue");
            }
            return isDocumentSearchCriteriaSelected() ? t("Save to 360") : t("Continue");
        }
        if (messageState.stepWizard === ChatPanelStep.DocumentView) {
            return t("Save to 360");
        }
    }, [isDocumentSearchCriteriaSelected, isNewCaseSelected, messageState.stepWizard]);

    const onDefaultClick = useCallback(() => {
        if ((showMeetingSelection && defaultButtonTextInMeetingContext() === t("Cancel")) ||
            (!showMeetingSelection && defaultButtonTextInChatContext() === t("Cancel"))) {
            onClosePanel();
        }
        else if ((showMeetingSelection && defaultButtonTextInMeetingContext() === t("Go back")) ||
            (!showMeetingSelection && defaultButtonTextInChatContext())) {
            let stepNumber = messageState.stepWizard;
            if (stepNumber > 0) {
                stepNumber = stepNumber - 1;
            }
            dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: stepNumber } });
        }
    }, [defaultButtonTextInChatContext, defaultButtonTextInMeetingContext, dispatch, messageState.stepWizard, onClosePanel, showMeetingSelection]);

    const getSaveRequestParameter = useCallback(async () => {
        const token = await teamsState.getAccessToken();
        const meetingsData: ISelectedMeetingData[] = (selectedMessages as IMessage[])?.map((meeting) => {
            const meetingData: ISelectedMeetingData = {
                id: meeting.Id as string,
                title: '',
                selectedItems: []
            };
            return meetingData;
        });

        const request: ArchiveMeetingRequestParameter = {
            accessToken: token,
            teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest,
            caseDocumentRequest: messageState.caseDocumentData,
            meetingResponse: messageState.meetingState,
            isRequestForArchiveRecording: showMeetingSelection as boolean,
            selectedMeetings: showMeetingSelection ? messageState.selectedMeetings : meetingsData
        };
        return request;
    }, [messageState.caseDocumentData, messageState.meetingState, messageState.selectedMeetings, selectedMessages, showMeetingSelection, teamsState]);

    const onSaveUsingCaseAndDocument = useCallback(async () => {
        const request = await getSaveRequestParameter();
        const response = await dataProvider?.P360.archiveMeetings(request);
        if (response?.data) {
            onSaveComplete && onSaveComplete(response.data);
        }
    }, [dataProvider?.P360, getSaveRequestParameter, onSaveComplete]);

    const onSaveExistingDocument = useCallback(async () => {
        const request = await getSaveRequestParameter();
        const response = await dataProvider?.P360.archiveMeetingOnExistingDocument(request);
        if (response?.data) {
            onSaveComplete && onSaveComplete(response.data);
        }
    }, [dataProvider?.P360, getSaveRequestParameter, onSaveComplete]);

    const onSaveClick = useCallback(async () => {
        savingInProgress();
        isArchivingInProgress(true);
        if ((showMeetingSelection && messageState.stepWizard === MeetingPanelStep.DocumentView) ||
            (!showMeetingSelection && messageState.stepWizard === ChatPanelStep.DocumentView)) {
            await onSaveUsingCaseAndDocument();
        }
        else if ((showMeetingSelection && messageState.stepWizard === MeetingPanelStep.CaseView) ||
            (!showMeetingSelection && messageState.stepWizard === ChatPanelStep.CaseView)) {
            if (isDocumentSearchCriteriaSelected())
                await onSaveExistingDocument();
        }
        closePanel();
    }, [closePanel, isArchivingInProgress, isDocumentSearchCriteriaSelected, messageState.stepWizard, onSaveExistingDocument, onSaveUsingCaseAndDocument, savingInProgress, showMeetingSelection]);

    const validate = useCallback(() => {
        if ((showMeetingSelection && messageState.stepWizard === MeetingPanelStep.CaseView) ||
            (!showMeetingSelection && messageState.stepWizard === ChatPanelStep.CaseView)) {
            return messageCaseViewRef.current?.validate();
        }
        else if ((showMeetingSelection && messageState.stepWizard === MeetingPanelStep.DocumentView) ||
            (!showMeetingSelection && messageState.stepWizard === ChatPanelStep.DocumentView)) {
            return messageNewDocumentViewRef.current?.validate();
        }
        return true;
    }, [messageCaseViewRef, messageNewDocumentViewRef, messageState.stepWizard, showMeetingSelection]);

    const onPrimaryClick = useCallback(() => {
        if (!validate())
            return;
        if ((showMeetingSelection && primaryButtonTextInMeetingContext() === t("Save to 360")) ||
            (!showMeetingSelection && primaryButtonTextInChatContext() === t("Save to 360"))) {
            onSaveClick();
        }
        else if (showMeetingSelection && primaryButtonTextInMeetingContext() === t("Continue")) {
            switch (messageState.stepWizard) {
                case MeetingPanelStep.MeetingSelectionView:
                    dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: MeetingPanelStep.CaseView } });
                    break;
                case MeetingPanelStep.CaseView:
                    dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: MeetingPanelStep.DocumentView } });
                    break;
            }
        }
        else if (!showMeetingSelection && primaryButtonTextInChatContext() === t("Continue")) {
            if (messageState.stepWizard === ChatPanelStep.CaseView) {
                dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: ChatPanelStep.DocumentView } });
            }
        }
    }, [validate, showMeetingSelection, primaryButtonTextInMeetingContext, primaryButtonTextInChatContext, onSaveClick, messageState.stepWizard, dispatch]);

    return <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px', justifyContent: 'flex-end' }}>
        <DefaultButton disabled={isSavingInProgress} text={showMeetingSelection ? defaultButtonTextInMeetingContext() : defaultButtonTextInChatContext()} onClick={onDefaultClick} />
        <PrimaryButton disabled={isSavingInProgress} text={showMeetingSelection ? primaryButtonTextInMeetingContext() : primaryButtonTextInChatContext()} onClick={onPrimaryClick} />
    </div>;
};